import React, { useState } from 'react';

import { useRouter } from 'next/router';

import { alpha, BoxProps, useTheme } from '@mui/material';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { PropertyMenu } from './PropertyMenu/PropertyMenu';
import { PropertyContent } from './PropertyContent/PropertyContent';
import { InOutIcon } from '@/components/shared/InOutIcon/InOutIcon';

import { StyledToolTip } from '@/components/lib';

import {
  PropertyActionsContainer,
  PropertyContainer,
  PropertyContentInnerContainer,
  PropertyMenuIcon,
  PropertyScoreColor
} from './Property.styles';

import { routes } from '@/helpers/routes';
import { shakingAnimation } from '@/helpers/animations';
import { getAppStorage } from '@/helpers/utils/localStorage';
import { useScoreColor } from '@/helpers/hooks/useScoreColor';
import {
  CategoryPropertyValue,
  Operator,
  ReadPropertyConfigSchema,
  PropertyDefinitionSchema
} from '@/helpers/services/api';

import { constants } from '../../overview.constants';
import {
  iconsDictionary,
  constants as interactionTypesConstants
} from '@/components/shared/InteractionTypeDropDown/interactionTypes.constants';

export interface ReadPropertyConfigSchemaExtended extends ReadPropertyConfigSchema {
  order?: string;
}

interface PropertyProps extends BoxProps {
  isDragAndDrop: boolean;
  interactionType: string;
  activeOrder?: null | string;
  isAllInteractionTypes: boolean;
  propertyInfo: PropertyDefinitionSchema;
  propertyData: ReadPropertyConfigSchemaExtended;
  score: number | CategoryPropertyValue[] | undefined;
  refetchProperties: () => void;
  setCurrentProperty: (property: ReadPropertyConfigSchemaExtended) => void;
}

export const Property = (props: PropertyProps) => {
  const {
    score,
    activeOrder,
    propertyData,
    propertyInfo,
    isDragAndDrop,
    interactionType,
    isAllInteractionTypes,
    refetchProperties,
    setCurrentProperty,
    ...otherProps
  } = props;

  const { attributes, listeners, transform, transition, setNodeRef, isDragging } = useSortable({
    id: `${propertyData.order}`
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transform ? transition : 'none',
    background: isDragging ? 'rgba(127, 207, 250, 0.3)' : '#FFFFFF'
  };

  const isAnimationNeed = isDragAndDrop && !isDragging && !activeOrder;

  const [hover, setHover] = useState(false);
  const [anchorElRootMenu, setAnchorElRootMenu] = useState<HTMLElement | null>(null);

  const { push } = useRouter();

  const theme = useTheme();
  const scoreColor = useScoreColor(Number(score), propertyData?.condition as Operator, Number(propertyData?.threshold));

  const { interactionType: interactionTypeStorage } = getAppStorage();

  const scoreDynamicColor = hover ? scoreColor : theme.palette.grey[600];

  const interactionTypesList = Array?.isArray(interactionTypeStorage?.options)
    ? interactionTypeStorage?.options
    : interactionTypesConstants?.types;

  const interactionTypeInfo = interactionTypesList?.find(
    el => el.name?.toLowerCase() === propertyData.interaction_type?.toLowerCase()
  );

  const handleStartHover = () => setHover(true);

  const handleStopHover = () => setHover(false);

  const handleOpenRootMenu = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorElRootMenu(event.currentTarget as unknown as HTMLElement);
  };

  const handleCloseRootMenu = () => {
    setAnchorElRootMenu(null);
    handleStopHover();
  };

  const handleRedirect = () => {
    push(
      `${routes.config.properties}?drillDownPropertyName=${propertyData?.text_property}&drillDownPropertyType=${propertyData?.kind}`
    );
  };

  const currentProps = {
    ...otherProps,
    ...(isDragAndDrop
      ? {
          style: style,
          sx: { animation: isAnimationNeed ? `${shakingAnimation} 0.6s infinite ease` : 'none' },
          ref: setNodeRef,
          ...listeners,
          ...attributes
        }
      : {
          onMouseOver: handleStartHover,
          onMouseLeave: handleStopHover
        })
  };

  if (isDragging) {
    return <PropertyContainer {...currentProps} />;
  }

  return (
    <PropertyContainer
      score={score}
      data-testid={`PropertyContainer${propertyData.text_property}`}
      background={
        interactionType && isAllInteractionTypes
          ? alpha(interactionTypeInfo?.color_code || theme.palette?.common?.white, 0.1)
          : theme.palette?.common?.white
      }
      {...currentProps}
    >
      <StyledToolTip
        text={propertyInfo.property_name}
        descriptions={`${propertyInfo.description} ${score ? '' : `\n\n ${constants.properties.noValueDescription}`}`}
        link={{ onClickOverride: handleRedirect, label: constants.properties.propertyMenu.linkToDocs }}
      >
        <PropertyContentInnerContainer
          data-testid={`PropertyContentInner${propertyData.id}`}
          onClick={() => (isDragAndDrop || score == undefined ? {} : setCurrentProperty(propertyData))}
        >
          <PropertyContent propertyData={propertyData} score={score} scoreDynamicColor={scoreDynamicColor} />
        </PropertyContentInnerContainer>
      </StyledToolTip>
      <PropertyActionsContainer svg_color={`${interactionTypeInfo?.color_code}`}>
        {hover && !isDragAndDrop ? (
          <PropertyMenuIcon onClick={handleOpenRootMenu} />
        ) : (
          (iconsDictionary[Number(interactionTypeInfo?.icon_id)] ?? <PropertyScoreColor background={scoreColor} />)
        )}
        <InOutIcon kind={propertyData?.kind} margin="0 0 0 auto" />
      </PropertyActionsContainer>
      <PropertyMenu
        open={!!anchorElRootMenu}
        anchorEl={anchorElRootMenu}
        propertyData={propertyData}
        refetchProperties={refetchProperties}
        handleCloseRootMenu={handleCloseRootMenu}
      />
    </PropertyContainer>
  );
};
