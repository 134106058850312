import React, { useEffect, useState } from 'react';

import { Stack } from '@mui/material';

import { InOutIcon } from '@/components/shared/InOutIcon/InOutIcon';
import { InteractionTypeDropDown } from '@/components/shared/InteractionTypeDropDown/InteractionTypeDropDown';
import { SamplesTablePopoverSortByProperty } from '@/components/Samples/SamplesTable/SamplesTablePopover/SamplesTablePopoverSortByProperty/SamplesTablePopoverSortByProperty';

import { StyledDialog, StyledOutlinedDropdown, StyledText } from '@/components/lib';
import {
  StyledPopover,
  InteractionTypeContainer,
  PropertiesDialogPropertyDropdown
} from './AddOrEditPropertyDialog.styles';
import { PropertiesDialogInnerContainer, PropertiesDialogTextInput, PropertiesErrText } from '../Properties.styles';

import { resError } from '@/helpers/services/resHandlers';
import { getAppStorage } from '@/helpers/utils/localStorage';
import { propertyConditionsDropdownValues } from './addOrEditPropertyDialog.helpers';
import {
  Operator,
  OrderByModel,
  PropertyType,
  PropertyColumnType,
  ReadPropertyConfigSchema,
  PropertyDefinitionSchema,
  createOrUpdatePropertyConfig,
  useListPropertiesDefinitions
} from '@/helpers/services/api';

import { constants } from '../../overview.constants';

export interface AddOrEditPropertyDialogProps {
  open: boolean;
  selectedAppId: number;
  interactionType?: string;
  propertyData?: ReadPropertyConfigSchema;
  closeMenu?: () => void;
  closeDialog: () => void;
  refetchProperties: () => void;
}

const { conditionLabel, thresholdConst, propertyLabel, interactionTypeLabel, chooseProperty, titleAndSubmit } =
  constants.properties.dialog;

export const AddOrEditPropertyDialog = (props: AddOrEditPropertyDialogProps) => {
  const {
    open,
    selectedAppId,
    propertyData,
    interactionType: interactionTypeProp,
    closeMenu,
    closeDialog,
    refetchProperties
  } = props;

  const { appId, type, interactionType: interactionTypeStorage } = getAppStorage();

  const [err, setErr] = useState('');
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [interactionType, setInteractionType] = useState(interactionTypeProp ?? '');
  const [kind, setKind] = useState<string | PropertyType>(propertyData?.kind || '');
  const [threshold, setThreshold] = useState(propertyData?.threshold?.toString() ?? '');
  const [condition, setCondition] = useState<string | number>(propertyData?.condition || '');
  const [property, setProperty] = useState<string | number>(propertyData?.text_property || '');

  const { data: propertylist, refetch } = useListPropertiesDefinitions(selectedAppId, {
    enviroment: type,
    interaction_type: interactionTypeProp
  });

  const disabledSubmit =
    (propertyData?.threshold === +threshold && propertyData?.condition === condition) || !property || !kind;
  const selectedProperty = Array.isArray(propertylist)
    ? propertylist?.filter(p => p?.property_name === property && p?.property_type === kind)[0]
    : ({} as PropertyDefinitionSchema);

  const isEditMode = !!propertyData?.id;
  const isCategorical = selectedProperty?.column_type === PropertyColumnType['categorical'];

  const handleCloseDialog = () => {
    closeDialog();
    setKind('');
    setProperty('');
    setThreshold('');
    setCondition('');
    setAnchorEl(null);
  };

  const filteredPropertylist = Array.isArray(propertylist)
    ? propertylist?.filter(
        property =>
          propertyData ||
          !property.in_dashboard ||
          property.interaction_type?.toLowerCase() !== interactionType?.toLowerCase()
      )
    : [];

  const submitButtonAction = async () => {
    setLoading(true);

    const handleFinishCall = () => {
      setErr('');
      setThreshold('');
      setLoading(false);
      refetchProperties();
      handleCloseDialog();
      setKind(propertyData?.kind || '');
      setCondition(propertyData?.condition || '');
      setProperty(propertyData?.text_property || '');

      closeMenu && closeMenu();
    };

    await createOrUpdatePropertyConfig({
      is_hidden: false,
      in_dashboard: true,
      application_id: appId,
      kind: kind as PropertyType,
      text_property: `${property}`,
      threshold: Number(threshold),
      interaction_type: interactionType,
      condition: (condition as Operator) || null
    }).then(res => {
      if ((res as resError)?.error_message) {
        setErr((res as resError)?.error_message);
        setLoading(false);
      } else {
        handleFinishCall();
      }
    });
  };

  const handleApplyClick = (newKind: OrderByModel, newProperty?: string) => {
    setKind(newKind.split('_properties')[0]);

    if (newProperty) {
      setProperty(newProperty);
    }

    setAnchorEl(null);
  };

  useEffect(() => {
    if (open) {
      refetch();

      if (propertyData) {
        setKind(propertyData.kind);
        setProperty(propertyData.text_property);
        setCondition(propertyData.condition || '');
        setThreshold(propertyData?.threshold?.toString() ?? '');
      }
    }
  }, [open]);

  if (!open) return null;

  return (
    <StyledDialog
      open={open}
      isLoading={loading}
      submitButtonWidth="150px"
      title={titleAndSubmit(isEditMode)}
      submitButtonDisabled={disabledSubmit}
      submitButtonLabel={titleAndSubmit(isEditMode)}
      closeDialog={handleCloseDialog}
      submitButtonAction={submitButtonAction}
    >
      <PropertiesDialogInnerContainer>
        <Stack>
          <StyledText text={propertyLabel} type="small" />
          <PropertiesDialogPropertyDropdown
            disabled={isEditMode}
            data-testid="AddPropertySelect"
            is_active={Boolean(anchorEl).toString()}
            no_property={!property ? 'true' : 'false'}
            onClick={e => !isEditMode && setAnchorEl(e.currentTarget)}
          >
            <StyledText text={property || chooseProperty} />
            {property && <InOutIcon kind={kind as PropertyType} />}
          </PropertiesDialogPropertyDropdown>
        </Stack>
        <StyledPopover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
          <SamplesTablePopoverSortByProperty
            column={property as string}
            propertylist={filteredPropertylist || []}
            model={`${kind}_properties` as OrderByModel}
            handleApplyClick={handleApplyClick}
          />
        </StyledPopover>
        <InteractionTypeContainer>
          <StyledText text={interactionTypeLabel} type="small" />
          <InteractionTypeDropDown
            noLabel
            outline
            interactionType={interactionType}
            appInteractionTypes={interactionTypeStorage?.options}
            setInteractionType={setInteractionType}
          />
        </InteractionTypeContainer>
        {!isCategorical && (
          <>
            <StyledOutlinedDropdown
              label={conditionLabel}
              value={condition || ''}
              data={propertyConditionsDropdownValues}
              setValue={setCondition}
            />
            <PropertiesDialogTextInput
              value={threshold}
              label={thresholdConst.label}
              placeholder={thresholdConst.placeholder(
                Number(selectedProperty?.min_value),
                Number(selectedProperty?.max_value)
              )}
              onChange={e => setThreshold(e.target.value)}
            />
          </>
        )}
        {err && <PropertiesErrText text={err} type="bodyBold" />}
      </PropertiesDialogInnerContainer>
    </StyledDialog>
  );
};
